<template>
    <div>
        <Navbar/>
        <PageTitle pageTitle="Frontend Engineer (m/w/d)"/>
        <Listing/>
        <CoolLightBox
                :items="items"
                :fullScreen="true"
                :index="index"
                @close="index = null">
        </CoolLightBox>

        <div class="project-details-area ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="projects-details-desc">
                            <h3>Frontend Engineer für CFTools Cloud</h3>
                            <p>
                                Mit CFTools Cloud bieten wir eine SaaS-Lösung für die Administration und Verwaltung von
                                Game-Servern an.<br/>
                                Unsere Mission ist es, die Einstiegsbarrieren in diesem Bereich zu überwinden und
                                einfache, aber leistungsstarke Werkzeuge für den Gaming-Sektor bereitzustellen.<br/>
                                <br/>
                                Wir sind ein kleines und vielseitiges Team, das mit Leidenschaft hinter unserem Produkt
                                steht um unseren Nutzern das bestmögliche Administrationserlebnis zu bieten.<br/>
                            </p>

                            <div class="row mt-4">
                                <div class="col-5">
                                    <h3>Dein Profil</h3>
                                    <div class="features-text">
                                        <h4>Technologie</h4>
                                        <p>
	                                        Ausgiebige Erfahrungen mit VueJS, Stateless UI und Integrationen mit Web APIs.
                                        </p>
                                    </div>
                                    <div class="features-text">
                                        <h4>Sprachkenntnisse</h4>
                                        <p>
                                            Verhandlungsfähig in Deutsch und English sowohl in Wort als auch Schrift.
                                        </p>
                                    </div>
                                    <div class="features-text">
                                        <h4>Mindset</h4>
                                        <p>
                                            Der Satz "Never change a running system" ist nicht Bestandteil deines
                                            Vokabular und du schreckst nicht vor brechenden Änderungen zurück.
                                        </p>
                                    </div>

                                    <div class="features-text">
                                        <h4>Kommunikation</h4>
                                        <p>Deine Vorstellungen und Ideen kannst du präzise und effektiv vermitteln.</p>
                                    </div>
                                    <div class="features-text">
                                        <h6>Wohnhaft in Deutschland</h6>
                                    </div>


                                </div>
                                <div class="col-5">
                                    <h3>Deine Aufgaben</h3>
                                    <div class="features-text">
                                        <h4>Weiterentwicklung unserer CFTools Cloud Platform</h4>
                                        <p>Du arbeitest an einer Softwarelösung in einem Wachstumssektor in dem deine Arbeit von echten Menschen erlebt wird.</p>
                                    </div>
                                    <div class="features-text">
                                        <h4>Unangekündigte Projekte</h4>
                                        <p>Lust auf etwas ganz neues? Du hast die Möglichkeit an noch unangekündigten
                                            Projekten zu arbeiten und diese federführend zu entwickeln.</p>
                                    </div>
                                    <div class="features-text">
                                        <h4>Dein eigener Fokus</h4>
                                        <p>Wir bieten diverse Einsatzmöglichkeiten, und arbeiten in unterschiedlichen Themenbereichen. Ob Webentwicklung, APIs, Cloud oder Networking, du kannst dir aussuchen woran du arbeitest.</p>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <h3>Unser Angebot</h3>
                            <div class="features-text">
                                <p>
                                    Wir bieten dir eine Full-Remote Stelle mit komplett flexiblen Arbeitszeiten. Wir
                                    lassen dir freie Hand und überlassen dir viele kreative Freiheiten. Du arbeitest in
                                    einem kleinen Team, ohne große formale Vorschriften.
                                </p>

                                <div class="project-details-info">
                                    <div class="single-info-box" style="min-width: 50% !important;">
                                        <h4>Home-Office</h4>
                                        <p>
                                            Full Remote nach der Orientierung und Onboarding.
                                        </p>
                                    </div>
                                    <div class="single-info-box" style="min-width: 50% !important;">
                                        <h4>Arbeitszeiten</h4>
                                        <p>
                                            Komplett flexibel. Du kannst dir deinen Arbeitsalltag frei einteilen.
                                        </p>
                                    </div>
                                </div>
                                <div class="project-details-info">
                                    <div class="single-info-box" style="min-width: 50% !important;">
                                        <h4>Gestalten</h4>
                                        <p>
                                            In deinen Aufgaben lassen wir dir freie Hand und du hast viele kreative Freiheiten.
                                        </p>
                                    </div>
                                    <div class="single-info-box" style="min-width: 50% !important;">
                                        <h4>Work-Life-Balance</h4>
                                        <p>
                                            Neben 30 Tagen Urlaub hast du auch frei über Weihnachten und Neujahr. Damit hast du genug Zeit um die Dinge zu tun, die für dich wichtig sind.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="project-details-info">
                                <div class="single-info-box">
                                    <h4>Kategorie</h4>
                                    <span>Engineering</span>
                                </div>

                                <div class="single-info-box">
                                    <h4>Projekt</h4>
                                    <span>CFTools Cloud</span>
                                </div>

                                <div class="single-info-box">
                                    <h4>Datum</h4>
                                    <span>ASAP</span>
                                </div>

                                <div class="single-info-box">
                                    <a href="mailto:info@cftools.software?subject=Bewerbung - Frontend Engineer"
                                       target="_blank" class="default-btn">Jetzt Bewerben<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Navbar from '../../../components/Layout/Navbar'
import PageTitle from '../../../components/Common/PageTitle'
import Footer from '../../../components/Layout/Footer'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    name: 'ProjectsDetailsContent',
    components: {
        Navbar,
        PageTitle,
        Footer,
        CoolLightBox
    },
    data: function () {
        return {
            index: null
        };
    },
}
</script>